import GTMService from "@/services/utils/GTMService";
import React, { useEffect } from 'react';
import { ToastProvider } from 'react-toast-notifications';
import CartProvider from "../context/cart/CartProvider";
import ProductProvider from "../context/product/ProductProvider";
import UserProvider from "../context/user/UserProvider";
import { appWithTranslation } from '../i18n';
import Toast from "../shared/toast/Toast";
import ToastContainer from "../shared/toast/ToastContainer";
import '../static/styles/App.scss';
import Head from "next/head";
import LinksProvider from "@/services/http/LinksProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import '../../polyfill'
import { useRouter } from "next/router";
import '@fortawesome/fontawesome-free/css/all.css';

const packageJson = require('../../package.json');


// Sentry.init({
//   dsn: process.env.REACT_SENTRY_DSN,
//   environment: process.env.NODE_ENV,
//   release: packageJson.version,
//   debug: true,
//   attachStacktrace: true
// });

function getCanonicalUrl(path) {
  const baseUrl = process.env.REACT_FRONTEND_BASE_URL
  return `${baseUrl}${path}`;
}


const MyApp = ({Component, pageProps}) => {
  const router = useRouter();
  useEffect(() => {
    GTMService.initialize();
  }, []);

  return (
    <React.Fragment>
      <Head>
        <meta charSet="utf-8"/>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
        <meta
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
          name="viewport"
        />
        <link rel="canonical" href={getCanonicalUrl(router.asPath)} />
        <title>Liste de mariage unique et personnalisée | Mille et une listes</title>


        <meta name="description"
              content="La liste de mariage gratuite et personnalisée pour se faire plaisir aux Galeries Lafayette, chez La Redoute, au BHV Marais et en récupérant l’argent sur son compte bancaire" key={"description"}/>
        <meta property="og:type" content="website" key={"og_type"}/>
        <meta property="og:title" content="Liste de mariage unique et personnalisée | Mille et une listes" key={"og_title"}/>
        <meta property="og:url" content={LinksProvider.getFullRoute(LinksProvider.ROUTES.HOME)} key={"og_url"}/>
        <meta property="og:description"
              content="Le luxe du choix avec la liste de mariage des Galeries Lafayette et du BHV MARAIS" key={"og_description"}/>
        <meta property="fb:app_id" content={process.env.REACT_FACEBOOK_APP_ID} key={"fb_app_id"}/>
        <meta property="og:image" content={LinksProvider.getFullRoute('/static/images/logos/og-image.jpeg')} key={"og_image"}/>
        <meta property="og:image:width" content="1200" key={"og_image_width"}/>
        <meta property="og:image:height" content="630" key={"og_image_height"}/>
        <meta name="google-site-verification" content={process.env.REACT_APP_GOOGLE_SITE_VERIFICATION} />
      </Head>
      <GoogleOAuthProvider clientId={process.env.REACT_GOOGLE_APP_ID}>
        <ToastProvider components={{Toast: Toast, ToastContainer: ToastContainer}}>
          <UserProvider>
            <ProductProvider>
              <CartProvider>
                <Component {...pageProps} />
              </CartProvider>
            </ProductProvider>
          </UserProvider>
        </ToastProvider>
      </GoogleOAuthProvider>
    </React.Fragment>
  );
};


MyApp.getInitialProps = async ({Component, ctx}) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  let pageProps = {}
  if (Component && ctx && Component.WrappedComponent?.getInitialProps) {
    const wrapperPageProps = await Component.WrappedComponent.getInitialProps(ctx)
    pageProps = { ...pageProps, ...wrapperPageProps }
  }
  return { pageProps };

};

export default appWithTranslation(MyApp);
